/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect } from "react";
import PhotoProps from "../types/photoProps";
import {
  pushImageToS3,
  uploadExternalImageUrl,
} from "../../../utils/helpers/uploadFileUtil";
import UploadImages from "../../services/components/upload/UploadImages";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { getJpegFileFromHeic } from "./utils";
import {
  checkApplePatentImageExtension,
  getFileExtensionFromType,
} from "../../logo/components/utils";

interface AllPhotosProps {
  setParentLocalGallery: React.Dispatch<React.SetStateAction<PhotoProps[]>>;
  filter: string;
  localGallery: any;
  setLocalGalleryState: any;
  setSelectedPhotos: any;
  context?: any;
}

const UploadFiles: React.FC<AllPhotosProps> = ({
  setParentLocalGallery,
  filter,
  localGallery,
  setLocalGalleryState,
  setSelectedPhotos,
  context,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [loading, setLoading] = useState(false);
  const [allUploadedFiles, setAllUploadedFiles] = useState<any>([]);

  useEffect(() => {
    if (allUploadedFiles.length) {
      allUploadedFiles.forEach((item) =>
        addImageToGallery(item["completePhotoUrl"], item["fileName"])
      );
    }
  }, [allUploadedFiles]);

  const addImageToGallery = (
    completePhotoUrl: string,
    signedS3Photo: string
  ) => {
    const galleryWithUploadedPhotos = [...localGallery];
    const globalGalleryWithUploadedPhotos = [...globalStateAtom.gallery];

    const maxAllPhotosOrder = globalGalleryWithUploadedPhotos.reduce(
      (max, photo) => {
        return photo.all_photos_order > max ? photo.all_photos_order : max;
      },
      0
    );
    const maxGalleryOrder = galleryWithUploadedPhotos.reduce((max, photo) => {
      return photo.gallery_order > max ? photo.gallery_order : max;
    }, 0);

    const galleryOrder = context === "All Photos" ? -1 : maxGalleryOrder + 1;

    const uploadedImageObject = {
      photo: completePhotoUrl,
      all_photos_order: maxAllPhotosOrder + 1,
      gallery_order: galleryOrder,
      homepage_order: -1,
      gallery_name: filter,
      associated_service: null,
      alt_tag: `${filter}-image`, // TODO: change this
      saved: false,
      fileName: signedS3Photo,
    };

    galleryWithUploadedPhotos.unshift(uploadedImageObject);
    globalGalleryWithUploadedPhotos.unshift(
      // @ts-ignore
      uploadedImageObject
    );

    setLocalGalleryState(galleryWithUploadedPhotos);

    if (context === "All Photos") {
      setGlobalState({
        ...globalStateAtom,
        gallery: galleryWithUploadedPhotos,
      });
    } else {
      setGlobalState({
        ...globalStateAtom,
        gallery: globalGalleryWithUploadedPhotos,
      });
    }

    setSelectedPhotos({});
    setLoading(false);
  };

  const onChangeHandler = async (files: any) => {
    setLoading(true);

    for (let i = 0; i < files!.length; i++) {
      let curFile = files![i];
      if (/image\//.test(curFile?.type) === true) {
        const fileExtension = getFileExtensionFromType(curFile.type);
        if (checkApplePatentImageExtension(fileExtension)) {
          curFile = await getJpegFileFromHeic(curFile);
        }
      }

      const [completePhotoUrl, signedS3Photo] = await pushImageToS3(curFile);
      if (completePhotoUrl) {
        const photo = {
          completePhotoUrl,
          fileName: signedS3Photo,
        };
        setAllUploadedFiles([...allUploadedFiles, photo]);
      }
    }
  };

  const onDropHandler = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      await onChangeHandler(files);
    }
    setLoading(false);
  };

  return (
    <div className="w-64 h-64" style={{ flexBasis: "30%" }}>
      <UploadImages
        onChangeHandler={onChangeHandler}
        onDropHandler={onDropHandler}
        multiple={true}
      />
      {loading && <img src="/loading.gif" width="25px" />}
    </div>
  );
};

export default UploadFiles;
