/* eslint-disable */
import { useAtom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import { buttonSmall } from "../../../shared/styles";
import globalState from "../../../store";
import { ITestimonial } from "../../../types/types";

const AddReviewButton: React.FC<{loading: boolean;}> = ({loading}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const addNewReview = () => {
    const city = globalStateAtom.city
      ? globalStateAtom.city
      : globalStateAtom.service_area_array &&
        globalStateAtom.service_area_array[0] &&
        globalStateAtom.service_area_array[0][0];
    const state = globalStateAtom.state
      ? globalStateAtom.state
      : globalStateAtom.service_area_array &&
        globalStateAtom.service_area_array[0] &&
        globalStateAtom.service_area_array[0][1];
    const location = city + ", " + state;
    const defaultService =
      globalStateAtom.services &&
      globalStateAtom.services[0] &&
      globalStateAtom.services[0]["service-name"];
    const blankReviewObject: ITestimonial = {
      review: "",
      reviewer: "",
      service: defaultService,
      stars: "5",
      status: "approved",
      title: location,
      source: "",
      order:
        globalStateAtom &&
        globalStateAtom.testimonials &&
        globalStateAtom.testimonials.length,
      draggableId: uuidv4(),
      checked: false,
      landing_page: globalStateAtom.sitePk,
    };

    const newTestimonials = [
      ...globalStateAtom.testimonials,
      blankReviewObject,
    ];
    setGlobalState({
      ...globalStateAtom,
      testimonials: newTestimonials,
    });
  };

  return (
    <button
      disabled={loading}
      onClick={addNewReview}
      className={`${buttonSmall} fixed bottom-4 right-4 disabled:cursor-not-allowed disabled:opacity-50`}
    >
      Add Review
    </button>
  );
};

export default AddReviewButton;
