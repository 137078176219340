/* eslint-disable */
import { useState, useEffect } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import PhotoProps from "../types/photoProps";
import DraggablePhotoGallery from "./DraggablePhotoGallery";
import UploadFiles from "./UploadFiles";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { CheckIcon } from "@heroicons/react/24/solid";
import { getIndustryStockPhotos } from "./utils";
import SwitchToggle from "../../../shared/SwitchToggle";

interface AllPhotosProps {
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: any;
  setParentLocalGallery: React.Dispatch<React.SetStateAction<PhotoProps[]>>;
  handleSelect: any;
  filter: string;
  rerenderAfterDeletePhoto: (item: PhotoProps) => void;
  showMicroGalleryButtons: any;
}

const AllPhotosDraggable: React.FC<AllPhotosProps> = ({
  showMicroGalleryButtons,
  setSelectedPhotos,
  selectedPhotos,
  setParentLocalGallery,
  handleSelect,
  filter,
  rerenderAfterDeletePhoto,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [stockPhotosBool, setStockPhotosBool] = useState(false);
  const [stockPhotos, setStockPhotos] = useState<string[]>([]);
  const [usingStockPhotos, setUsingStockPhotos] = useState(false);

  const { gallery } = globalStateAtom;

  const [localGallery, setLocalGalleryState] = useState<any>(
    gallery.sort((a, b) => b.all_photos_order - a.all_photos_order)
  );

  const [homepagePhotos, setHomepagePhotos] = useState<any[]>(
    gallery
      .filter((photo) => photo.homepage_order !== -1)
      .sort((a, b) => b.homepage_order - a.homepage_order)
  );

  const [selectedHomepagePhotos, setSelectedHomepagePhotos] = useState<any[]>(
    []
  );
  const [settingOrder, setSettingOrder] = useState<boolean>(false);

  useEffect(() => {
    // globalState changes when a photo is moved from one gallery to another, so this ensures the current gallery live updates and the moved photo disappears live
    setLocalGalleryState([...globalStateAtom.gallery]);
    // 'filter' changes when the sidenav menu item changes -> the photos displayed change because of the setLocalGalleryState above
  }, [filter]);

  useEffect(() => {
    getStockPhotosByIndustry();

    setSelectedPhotos([]);
  }, []);

  useEffect(() => {
    checkForStockPhotos();
  }, [gallery]);

  useEffect(() => {
    if (
      stockPhotosBool &&
      !usingStockPhotos &&
      Array.isArray(stockPhotos) &&
      stockPhotos.length > 0
    ) {
      useIndustryStockPhotos();
    } else if (!stockPhotosBool && usingStockPhotos) {
      removeStockPhotos();
    }
  }, [stockPhotosBool, usingStockPhotos]);

  const removeStockPhotos = () => {
    const newGallery = gallery.filter((photo) => !photo?.stock || false);
    setLocalGalleryState(
      newGallery.sort((a, b) => b.all_photos_order - a.all_photos_order)
    );
    setGlobalState({
      ...globalStateAtom,
      gallery: newGallery,
    });
  };

  const useIndustryStockPhotos = () => {
    try {
      const maxOrder =
        gallery?.reduce((max, photo) => {
          return photo.all_photos_order > max ? photo.all_photos_order : max;
        }, 0) || 0;
      const newGallery = [
        ...gallery,
        ...stockPhotos.map((photo, index) => {
          return {
            photo,
            all_photos_order: maxOrder + index + 1,
            gallery_order: -1,
            homepage_order: -1,
            gallery_name: "",
            associated_service: null,
            alt_tag: `stock-image`,
            saved: false,
            stock: true,
          };
        }),
      ];
      setLocalGalleryState(
        newGallery.sort((a, b) => b.all_photos_order - a.all_photos_order)
      );
      setGlobalState({
        ...globalStateAtom,
        gallery: newGallery,
      });
    } catch (e) {
      console.log("Error formatting stock photos", e);
      alert("Error formatting stock photos");
    }
  };

  const checkForStockPhotos = () => {
    const usingStockPhotos = !!gallery.find((photo) => photo.stock);
    if (usingStockPhotos) {
      setStockPhotosBool(true);
      setUsingStockPhotos(true);
    } else {
      setUsingStockPhotos(false);
      setStockPhotosBool(false);
    }
  };

  // Function to ensure the state of the gallery is saved on drag
  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (destination.index === source.index) return null;

    // Move the item within the list
    // Start by making a new list without the dragged item
    const newList = [...localGallery].filter(
      (_: any, idx: number) => idx !== source.index
    );

    // Then insert the item at the right location
    newList.splice(destination.index, 0, localGallery[source.index]);
    // Update the list
    setLocalGalleryState(newList);
    // make sure the order of the items matches the order of the drag-n-drop
    const orderedList = newList.map((item: any, idx: any) => {
      return { ...item, order: idx };
    });
    // update the gallery with the photos' new orders
    const newGalleryArray = [...gallery];
    for (let i = 0; i < orderedList.length; i++) {
      const posOfImage = newGalleryArray.findIndex(
        (img) => img.photo === orderedList[i].photo
      );
      if (posOfImage !== -1) {
        newGalleryArray[posOfImage] = orderedList[i];
      }
    }

    // sort the gallery so the galleries are rendered in order
    const sortedGallery = newGalleryArray.sort(
      (a, b) => b.all_photos_order - a.all_photos_order
    );
    // update the parent components 'localGalleryState' so the changes show up live across all galleries
    setParentLocalGallery(newGalleryArray);
    // update the global state with the new orders for the photos in this gallery
    setGlobalState({ ...globalStateAtom, gallery: [...sortedGallery] });
  };

  const getStockPhotosByIndustry = async () => {
    const photos = await getIndustryStockPhotos(globalStateAtom.company_type);
    setStockPhotos(photos);
  };

  function selectPhoto(photo) {
    selectedPhotos = [...selectedHomepagePhotos];

    const alreadySelected = selectedHomepagePhotos.find(
      (p) => p.photo_pk === photo.photo_pk
    );

    if (alreadySelected) {
      const idToRemove = photo.photo_pk;
      const newSelectedPhotos = selectedPhotos.filter(
        (photo) => photo.photo_pk !== idToRemove
      );
      setSelectedHomepagePhotos(newSelectedPhotos);
    } else {
      selectedPhotos.push(photo);
      setSelectedHomepagePhotos(selectedPhotos);
    }
  }

  function reorderHomepagePhotos() {
    setSelectedHomepagePhotos([]);
    setSettingOrder(false);

    const selectedPhotos: any[] = [...selectedHomepagePhotos];
    const unselectedPhotos: any[] = [];

    homepagePhotos.forEach((p: any) => {
      if (!selectedPhotos.some((photo) => photo.photo_pk === p.photo_pk)) {
        unselectedPhotos.push(p);
      }
    });

    const photosCount = homepagePhotos.length;
    const indexesToReorder: any[] = [];
    for (let i = 1; i <= photosCount; i++) {
      indexesToReorder.push(i);
    }
    indexesToReorder.reverse();

    const selectedPhotosReordered = selectedPhotos.map((item, index) => {
      return { ...item, homepage_order: indexesToReorder[index] };
    });

    const currentGalleryReordered =
      selectedPhotosReordered.concat(unselectedPhotos);

    const newHomepagePhotos = currentGalleryReordered.map((item, index) => {
      return { ...item, homepage_order: indexesToReorder[index] };
    });

    setHomepagePhotos(newHomepagePhotos);
    setGlobalStateWithHomepagePhotos(newHomepagePhotos);
  }

  function checkIfSavedYet() {
    let saved = false;
    for (let i = 0; i < localGallery.length; i++) {
      if (localGallery[i].hasOwnProperty("photo_pk")) {
        saved = true;
        break;
      }
    }
    return saved;
  }

  function addSelectedPhotos() {
    const saved = checkIfSavedYet();
    if (saved) {
      const currentHomepagePhotos = [...homepagePhotos];

      for (const photoUrl in selectedPhotos) {
        const alreadyAdded = checkIfAlreadyAdded(photoUrl);
        if (!alreadyAdded) {
          currentHomepagePhotos.push(selectedPhotos[photoUrl]);
        }
      }

      const indexesToReorder: any[] = [];
      for (let i = 1; i <= currentHomepagePhotos.length; i++) {
        indexesToReorder.push(i);
      }
      indexesToReorder.reverse();

      const newHomepagePhotos = currentHomepagePhotos.map((item, index) => {
        return { ...item, homepage_order: indexesToReorder[index] };
      });

      setHomepagePhotos(newHomepagePhotos);
      setGlobalStateWithHomepagePhotos(newHomepagePhotos);

      setSelectedPhotos({});
    }
  }

  function checkIfAlreadyAdded(photoUrl) {
    const alreadyAdded = homepagePhotos.some(
      (photo) => photo.photo === photoUrl
    );

    if (alreadyAdded) {
      return true;
    }
    return false;
  }

  function setGlobalStateWithHomepagePhotos(newHomepagePhotos: any[]) {
    const allPhotos = globalStateAtom.gallery.map((currentImage) => {
      if (
        newHomepagePhotos.some((image) => image.photo === currentImage.photo)
      ) {
        return newHomepagePhotos.find(
          (image) => image.photo === currentImage.photo
        );
      } else {
        return { ...currentImage, homepage_order: -1 };
      }
    });
    const allPhotosReordered = allPhotos.sort(
      (a, b) => b.all_photos_order - a.all_photos_order
    );

    setGlobalState({
      ...globalStateAtom,
      gallery: allPhotosReordered,
    });
  }

  function removeSelectedPhotos() {
    const photosToRemoveIds = selectedHomepagePhotos.map((photo) => {
      return photo.photo_pk;
    });

    const newHomepagePhotos = [...homepagePhotos].filter(
      (photo) => !photosToRemoveIds.includes(photo.photo_pk)
    );

    setHomepagePhotos(newHomepagePhotos);
    setGlobalStateWithHomepagePhotos(newHomepagePhotos);
    setSelectedHomepagePhotos([]);
  }

  return (
    // We need to put both the <Droppable /> and <Draggable /> components within a context
    <>
      <div className="flex items-center mb-4">
        <div className="text-left text-lg font-bold mr-4">Homepage photos</div>

        <div
          onClick={removeSelectedPhotos}
          className="cursor-pointer rounded-lg bg-red-700 p-2 text-white mr-4"
        >
          Remove selected photos
        </div>

        {!settingOrder && homepagePhotos?.length > 0 && (
          <div
            onClick={() => setSettingOrder(true)}
            className="cursor-pointer rounded-lg bg-blue-800 p-2 text-white"
          >
            Set order
          </div>
        )}
        {settingOrder && (
          <div
            onClick={reorderHomepagePhotos}
            className="cursor-pointer rounded-lg bg-green-700 p-2 text-white"
          >
            Save order 🥳
          </div>
        )}
      </div>

      {homepagePhotos?.length > 0 && (
        <>
          <div className="flex flex-wrap">
            {homepagePhotos.map((photo) => {
              const selected = selectedHomepagePhotos.some(
                (p) => p.photo_pk === photo.photo_pk
              );
              return (
                <div
                  onClick={() => selectPhoto(photo)}
                  className="relative cursor-pointer mr-4 mb-4"
                >
                  <img className="w-28 h-28 object-cover" src={photo.photo} />
                  <div
                    className={`absolute bottom-2 right-2 w-5 h-5 rounded-full border-2 border-white shadow-lg justify-center items-center ${
                      selected && "bg-blue-600"
                    }`}
                  >
                    {selected && (
                      <CheckIcon className="w-3 h-full m-auto text-white" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {homepagePhotos?.length === 0 && (
        <div className="text-left mb-12">
          <div>Currently 0 photos pinned to the homepage!</div>
        </div>
      )}

      <div className="text-left my-8">
        <div className="underline font-bold mb-1">Reference</div>
        <div className="flex flex-row space-x-4">
          <div className="w-6 font-bold">v2</div>
          <div>9 homepage photos</div>
        </div>
        <div className="flex flex-row space-x-4">
          <div className="w-6 font-bold">v2.1</div>
          <div>6 homepage photos</div>
        </div>
        <div className="flex flex-row space-x-4">
          <div className="w-6 font-bold">v2.2</div>
          <div>16 homepage photos</div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex justify-start w-full">
          {/* TODO: add carousel of FBPhotoes here */}

          {/* The <Droppable /> component has <Draggable /> children and needs an id (see the other <Droppable /> component below, which has a different droppableId) */}
          <Droppable droppableId="col-1">
            {/* Both <Draggable /> and <Droppable /> need 'provided' props */}
            {(provided) => (
              // This div has the provided props and ref needed to drag <Draggables /> within it
              <div
                className="flex flex-col items-start"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ flexBasis: "70%" }}
              >
                <DraggablePhotoGallery
                  context="All Photos"
                  showMicroGalleryButtons={showMicroGalleryButtons}
                  setSelectedPhotos={setSelectedPhotos}
                  selectedPhotos={selectedPhotos}
                  handleSelect={handleSelect}
                  filter={filter}
                  setLocalGallery={setLocalGalleryState}
                  localGallery={localGallery}
                  rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
                  addSelectedPhotos={addSelectedPhotos}
                />
                {/* The Droppable needs to render a placeholder on the screen while the user drags an element */}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="ml-3">
            <UploadFiles
              context="All Photos"
              setGlobalState={setGlobalState}
              globalState={globalState}
              filter={filter}
              setParentLocalGallery={setParentLocalGallery}
              localGallery={localGallery}
              setLocalGalleryState={setLocalGalleryState}
              setSelectedPhotos={setSelectedPhotos}
            />
            <label
              className={`flex justify-center items-center gap-4 rounded-md py-2 px-4 mt-4 ${
                stockPhotosBool ? "bg-green-500" : "bg-white"
              }`}
            >
              Stock photos
              <SwitchToggle
                onChange={(e) => setStockPhotosBool(!stockPhotosBool)}
                checked={stockPhotosBool}
              />
            </label>
          </div>
        </div>
      </DragDropContext>
    </>
  );
};

export default AllPhotosDraggable;
