/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import globalState from "../../store";
import Column from "./components/Column";
import AdditionalLinks from "./components/AdditionalLinks";
import CustomHtml from "./components/CustomHtml";
import AddReviewButton from "./components/AddReviewButton";
import { pageContainerWithOverflow } from "../../shared/styles";
import CategorizeReviews from "./components/CategorizeReviews";
import { ITestimonial } from "../../types/types";

export default function ReviewsPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { testimonials } = globalStateAtom;

  // generating a consistent draggableId and key for each review
  useEffect(() => {
    const reviews = testimonials.map((item: ITestimonial) => {
      if (!item?.draggableId) {
        item.draggableId = uuidv4();
      }
      if (!item.source) {
        item.source = "Facebook";
      }
      item.checked = false
      return item;
    });

    // @ts-ignore
    setGlobalState({ ...globalStateAtom, testimonials: reviews });
  }, []);

  useEffect(() => {
    if (!mounted) setMounted(true);
  }, [testimonials]);

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (destination.index === source.index) return null;
    // console.log(destination, source)
    // Move the item within the list
    // Start by making a new list without the dragged item
    const newList = testimonials.filter(
      (_: any, idx: number) => idx !== source.index
    );

    // Then insert the item at the right location
    newList.splice(destination.index, 0, testimonials[source.index]);

    const newListWithOrder = newList.map((item: any, index: number) => {
      return { ...item, order: index };
    });

    // Update the list
    // @ts-ignore
    setGlobalState({ ...globalStateAtom, testimonials: newListWithOrder });
  };

  return !mounted ? (
    <img src="/loading.gif" width="25px" />
  ) : (
    <div className={pageContainerWithOverflow}>
      <div className="flex">
        <AdditionalLinks
          setGlobalState={setGlobalState}
          globalState={globalStateAtom}
        />
        <CustomHtml
          setGlobalState={setGlobalState}
          globalState={globalStateAtom}
        />
      </div>
      <AddReviewButton loading={loading} />

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="fixed bottom-4 left-4 lg:left-12 2xl:left-36 p-3 space-y-4">
          <h2>Reviews</h2>
          <br />
          <CategorizeReviews loading={loading} setLoading={setLoading} />
        </div>
        <div className="w-full flex justify-center">
          <Column loading={loading} />
        </div>
      </DragDropContext>
    </div>
  );
}
